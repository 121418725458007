* {
    /* box-sizing: border-box; */
    /* margin: 0;
    padding: 0; */
    /* font-family: Arial, Helvetica, sans-serif; */
}

.wrapper {
    width: 100%;
    /* height: 100vh; */
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 20;
}

.container {
    z-index: 20;
    height: 400px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    
}

.card {
    width: 160px;
    z-index: 20;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1.4rem;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transition: .6s cubic-bezier(.28,-0.03,0,.99);
    box-shadow: 0px 10px 30px -5px rgba(0,0,0,0.8);
}

.card > .row {
    /* color: white; */
    display: flex;
    flex-wrap: nowrap;
    z-index: 20;
    
}

.card > .row > .icon {
    /* background: #223; */
    z-index: 20;
    color: white;
    /* border-radius: 50%; */
    width: 300px;
    display: flex;
    
    /* justify-content: to  p; */
    /* align-items: end; */
    /* margin: 15px; */
    padding-bottom: 50px;
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .card{
        width: 90%;
        height: 80px;
        margin: 0;
        display: flex;
        align-items: center;
        transition: height 2s ease; 
    } 
    .container{
        flex-direction: column;
        row-gap: 30px;
        height: auto;
        align-items: center;
        transition: height 2s ease-in-out; 
    }
    .card > .row > .icon{
        width: 100%;
        margin: 0;
        padding: 0;
        
    }
    .wrapper{
        padding-top: 50px;
    }
    
}

/* .card > .row > .description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    height: 80px;
    width: 520px;
    opacity: 0;
    transform: translateY(30px);
    transition-delay: .3s;
    transition: all .3s ease;
} */

.description p {
    color: #b0b0ba;
    padding-top: 5px;
    z-index: 20;
}

.description h4 {
    z-index: 20;
    text-transform: uppercase;
}

input {
    display: none;
    z-index: 20;
}

input:checked + label {
    width: 335px;
    z-index: 20;
}

input:checked + label .description {
    opacity: 1 !important;
    z-index: 20;
    transform: translateY(0) !important;
}

.card[for="c1"] {
    background-image: url('../../assets/pic1.png');
    opacity: 0.8;
}
.card[for="c2"] {
    background-image: url('../../assets/pic2.png');
}
.card[for="c3"] {
    background-image: url('../../assets/pic3.png');
}
.card[for="c4"] {
    background-image: url('../../assets/owner.jpg');
}